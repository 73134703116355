










































































import { Component, Vue } from "vue-property-decorator";
import campos_personalizadoModule from "@/store/modules/campos_personalizado-module";
import { UtilsString } from "@/utils/utils-string";
import { UtilsEncript } from "@/utils/utils-encript";
import { UtilsNotify } from "@/utils/utils-notify";
import { RouterNames } from "@/router/routernames";
import cab_valor_campos_personalizadoModule from "@/store/modules/cab_valor_campos_personalizado-module";
@Component({
  components: {
    ComSlider: () => import("@/views/entrevista/CompSlider.vue"),
    CompFin: () => import("@/views/entrevista/CompFin.vue"),
    CompSelectFinalEntrevista: () =>
      import("@/views/entrevista/CompSelectFinalEntrevista.vue"),
  },
})
export default class CompMedicionesPersonalizadas extends Vue {
  public onboarding: number = 0;
  public id_paciente: number = 0;
  public id_mediciones: number = 0;
  public update: boolean = false;
  public dialogFin: boolean = false;
  public length: number = 0;
  public btns: any = [];
  public id_tipos_campos_personalizados: number = 0;

  async created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_mediciones)) {
      this.id_mediciones = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_mediciones)
      );
      await campos_personalizadoModule.getMedicionCampos(this.id_mediciones);
      this.update = true;
    } else {
      if (
        UtilsString.IsValidParamUrl(
          this.$route.query.id_tipos_campos_personalizados
        )
      ) {
        await campos_personalizadoModule.getNuevaMedicionCampos();

        this.id_tipos_campos_personalizados = Number.parseInt(
          UtilsEncript.DesEncriptar(
            this.$route.query.id_tipos_campos_personalizados
          )
        );

        this.length =
          campos_personalizadoModule.campos_personalizado_nueva_medi.filter(
            (x) =>
              x.id_tipos_campos_personalizados ===
              this.id_tipos_campos_personalizados
          ).length + 1;
      }
    }
    if (this.length === 0) {
      this.length =
        campos_personalizadoModule.campos_personalizado_nueva_medi.length + 1;
    }
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
    }
  }
  public get campos() {
    if (this.id_tipos_campos_personalizados > 0) {
      return campos_personalizadoModule.campos_personalizado_nueva_medi.filter(
        (x) =>
          x.id_tipos_campos_personalizados ===
          this.id_tipos_campos_personalizados
      );
    }
    return campos_personalizadoModule.campos_personalizado_nueva_medi;
  }

  public get max_value() {
    return Math.max(
      ...this.campos.map((x) => {
        return x.valor + 100;
      })
    );
  }

  public next() {
    this.onboarding =
      this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
  }
  public prev() {
    this.onboarding =
      this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
  }
  public fin() {
    if (this.update) {
      campos_personalizadoModule
        .actualizar_medicion({
          campos_personalizado_nueva: this.campos,
          id_paciente: this.id_paciente,
        })
        .then(() => this.guardado_correcto());
    } else {
      campos_personalizadoModule
        .guardarnueva_medicion({
          campos_personalizado_nueva: this.campos,
          id_paciente: this.id_paciente,
        })
        .then(() => this.guardado_correcto());
    }
  }
  public guardado_correcto() {
    UtilsNotify.NotificacionPrimary(
      "Mediciones",
      "Se han guardado correctamente"
    );
    this.btns = [
      {
        id: 99,
        titulo: "Imprimir en PDF",
        descripcion: "¿Queire imprimir las mediciones en PDF?",
        icono: "fad fa-file-pdf",
        visible: this.update,
      },
      {
        id: 100,
        titulo: "Ficha",
        descripcion: "Volver a la ficha del paciente",
        icono: "fad fa-user-check",
        visible: true,
      },
    ];
    this.dialogFin = true;
  }

  public async clickbutton(item: any) {
    switch (item.id) {
      case 99:
        await cab_valor_campos_personalizadoModule.getpdf_medicion(
          this.id_mediciones
        );
        break;
      case 100:
        this.$router.push({
          name: RouterNames.mediciones_personalizadas_lista,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
          },
        });
        break;
    }
  }
}
